.navbar {
  display: inline-block;
  position: fixed;
  z-index: 15;
  /* background-color: #006666; */
}
.iconDrawer {
  padding: 0.0rem 0.4rem;
  width: 2.4rem;
  margin: 0.6rem 0.4rem;
  /* margin: 0.0rem 2rem; */
}
.iconBack {
  width: 1.2rem;
  padding: 0.4rem 0.2rem;
  margin-bottom: 10px;
}
.closeBtn{
  display: none;
}
.navMenu {
  overflow: auto;
  padding: 0.6rem 0 0.6rem 0rem;
  background-color: #006666;
  position: fixed;
  z-index: 15;
  height: 100vh;
  width: 19vw;
  left: -100%;
  transition: 600ms;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.navMenu.active {
  left: 0;
  transition: 600ms;
}
.navMenu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.nav-item {
  /* border: 0.2px solid white; */
  padding: 1rem 0.6rem 1rem 0.6rem;
  font-size: 0.9rem;
  border-bottom: 1px solid #ccc;
}
.navMenu ul span {
  text-decoration: none;
  color: white;
  cursor: pointer;
}
.nav-item a span {
  color: white;
}
.dummyDiv {
  background-color: rgba(0, 0, 0, 0.36);
  color: white;
  position: fixed;
  z-index: 13;
  top: -100%;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: opacity 600ms ease-out;
  cursor: pointer;
}
.dummyDiv.active {
  opacity: 1;
  top: 0;
  transition: opacity 600ms ease-in;
}
.logoutBtn {
  margin: 2.5rem auto 3.8rem;
  padding: 0.6rem 0.8rem;
  font-size: 1rem;
  text-transform: uppercase;
  border-radius: 8px;
  border: 2px solid #ffffff;
  background-color: #006666;
  color: white;
  font-weight: bold;
  width: 150px;
}
.logoutIcon {
  /* margin-bottom: -0.4rem; */
  height: 22px;
}
.userDetail {
  color: whitesmoke;
  text-align: center;
  margin: 1rem 0;
}
.capital {
  text-transform: capitalize;
  margin-bottom: 0.4rem;
}

/* custom scroll bar for navbar only */

/* width */
.navContainer ::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.navContainer ::-webkit-scrollbar-track {
  background: transparent; 
}

/* Handle */
.navContainer ::-webkit-scrollbar-thumb {
  background: #ccc; 
  border-radius: 8px;
}

/* Handle on hover */
.navContainer ::-webkit-scrollbar-thumb:hover {
  background: #bcbcbc; 
}

@media only screen and (max-width: 768px) {
  .navMenu{
    width: 100vw;
  }
  .closeBtn{
    display: block;
  }
  .iconDrawer{
    width: 2rem;
  }
}
@media (min-width: 769px) and (max-width: 1279px) {
  .navMenu{
    width: 30vw;
  }
}