.backDiv {
  background-color: rgba(0, 0, 0, 0.28);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1500;
  text-align: center;
  height: 100vh;
  width: 100vw;
}
.loaderImg {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  overflow: hidden;
  display: inline-block;
  margin-top: 42vh;
}
