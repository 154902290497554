.alert {
  color: aliceblue;
  position: fixed;
  bottom: 0;
  margin: 1.6rem 0.6rem;
  padding: 0.8rem 1rem;
  border-radius: 8px;
  width: 96%;
  z-index: 1500;
}
.red{
  background-color: rgba(255, 0, 0, 0.78);
}
.green{
  background-color: #489963f0;
}
.errorImg {
  margin: 0 0.4rem 0.2rem 0;
}
.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 500ms, opacity 500ms linear;
}
.visible {
  visibility: visible;
  opacity: 1;
  transition: opacity 500ms linear;
}
