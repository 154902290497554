body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  color: #666;
}
button {
  cursor: pointer;
}
.hide {
  display: none;
}
.show {
  display: block;
}
.printIcon {
  height: 0.9rem;
  margin-bottom: -0.15rem;
}
.printIcon.white path {
  fill: white;
}
.pd-t-8{
  padding-top: 8px;
}
.fontXS {
  font-size: 0.7rem;
}
.fontS {
  font-size: 0.8rem;
  font-weight: 420;
}
.fontM {
  font-size: 1.1rem;
  font-weight: 550;
}
.fontL {
  font-size: 1.4rem;
  font-weight: 450;
}
.bold{
  font-weight: 700;
}
.green {
  color: #006666;
}
.lgreen {
  color: #009D8B;
}
.dgreen{
  color: #326C37;
}
.yellow {
  color: #f79204;
}
.red {
  color: #ff4d17;
}
.redbrd{
  border: 1px solid #fdc2c2;
  border-radius: 8px;
}
.pointer {
  cursor: pointer;
}
.not_allowed {
  cursor: not-allowed;
}
.default {
  cursor: default;
}
.labelGrey {
  color: #999;
}
.breadCrump {
  text-decoration: underline;
  cursor: pointer;
}
.navIcon{
  height: 22px;
  margin-right: 0.5rem;
  margin-top: -5px;
}
.visibility_hidden{
  visibility: hidden;
}
.dropdown_dummy_div {
  width: 100%;
  height: 100%;
  position: fixed;
  /* z-index: 1; */
  /* background-color: #f00; */
  left: 0;
  top: 0;
}
.zidx_10{
  z-index: 10;
}
.icon24{
  width: 24px;
  height: 24px;
  margin: 0 5px;
}
.icon16{
  width: 16px;
  height: 16px;
  margin: 0 5px;
}
.fillGreen path{
  fill: #006666;
}
.floatRight{
  float: right;
}
.textRight {
  text-align: right;
}
.textCenter {
  text-align: center;
}
.flex{
  display: flex;
}
.flex-space-between{
  justify-content: space-between;
}
.textUnderline{
  text-decoration: underline;
}
.brdGrey{
  border: 1px solid #ccc;
}
@media screen and (max-width: 768px) {
  .fontS{
    font-size: 11px;
    /* font-weight: 400; */
  }
  .navIcon{
    margin-left: 5px;
    width: 16px;
    /* visibility: hidden; */
  }
}